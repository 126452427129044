import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Rest Day. `}<em parentName="p">{`*`}{`If you will not be able to make it in Christmas Eve you can
do “12 Days of Christmas” wod today!`}</em></p>
    <p><strong parentName="p">{`*`}{`Monday, December 24th, Christmas Eve, The Ville will have our annual
“12 Days of Christmas” WOD at 10:30am.  Everyone is invited!  A scaled
version `}<em parentName="strong">{`(and RX+ version of course)`}</em>{` will be available as well.  The
gym will be closed other than this class.  We will be closed December
25th, Christmas Day, and then reopen Wednesday, December 26th starting
with our 10:30am class.`}</strong></p>
    <p><strong parentName="p">{`*`}{`CrossFit the Ville’s Barbell Club will be starting January 19th!  If
you’d like to improve your Clean & Jerk and Snatch these classes are for
you.  Classes will be offered Saturday’s and Sunday’s each weekend after
our CrossFit classes.  Email Daniel for more information.`}</strong></p>
    <p><strong parentName="p">{`*`}{`The Granite Games Throwdown in coming up January 12th.  You still
have time to register but we only have 5 spots left.  Get your teams
together and registered soon to reserve your spot! `}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://competitioncorner.net/events/1549?fbclid=IwAR0ST57-McRs4RyVzEL5D6sr_LV403-KCqTNNEKweUXuq32ASGMdLOHFY4s#.W_biri3Mz1w"
      }}>{`https://competitioncorner.net/events/1549?fbclid=IwAR0ST57-McRs4RyVzEL5D6sr_LV403-KCqTNNEKweUXuq32ASGMdLOHFY4s#.W_biri3Mz1w`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      